import React from 'react';
import basedProgram from "../../assets/img/foot_supplement/based-butrition-program.jpg";


const vegetarian_food_AdditionalAdvice = function () {
  return (
    <>
      <p className="nutritionHead2 ">คำแนะนำเพิ่มเติม</p>
      <p className="nutritionText3">
        ไม่แนะนำให้กิน Fitto Plant Protein เป็นมื้อเช้าและมื้อเที่ยงติดต่อกัน และกินอาหารปกติในมื้อเย็น ควรมีมื้อปกติคั่นกลาง หรือเริ่มด้วยมื้อปกติก่อน จะช่วยลดความหิวและลดโอกาสเสี่ยงที่จะกินอาหารมื้อปกติเกินปริมาณได้
      </p>
      <p className="nutritionText3 bold color1"> คำอธิบายการเลือกกินตามแนวทางที่แนะนำ</p>
      <p className="nutritionText3 bold">1. มื้อที่เลือกกิน Fitto Plant Protein</p>
      <p className="nutritionText4">
        สามารถเพิ่มการกินขนมปังโฮลวีต 1-2 แผ่น หรือดื่มคู่กับนมถั่วเหลือง นมธัญพืชสูตรหวานน้อยให้อิ่มท้องได้ หรืออาจกินผลไม้ ปริมาณ 1 กำปั้นก็ได้
      </p>
      <p className="nutritionText3 bold">2. มื้ออาหารหลัก</p>
      <p className="nutritionText4">
        <li>1. ควรเลือกเมนูที่ได้สัดส่วนของข้าว โปรตีนและมีผักทุกมื้อ</li>
        <li>2. มื้ออาหารหลัก ควรเลือกกินเมนูใดเมนูหนึ่งเท่านั้น จากในตารางที่กำหนดหรือเมนูเน้นพืชอื่น ๆ ที่สามารถหาได้ ซึ่งเมนูข้างต้น เป็นเพียงเมนูแนะนำเท่านั้น ไม่จำเป็นต้องกินตามอย่างเคร่งครัดและจำกัด สามารถเลือกกินเมนูอื่น ๆ ที่ใกล้เคียงหรือพลิกแพลงได้ตามความต้องการ</li>
        <li>3. หากเมนูที่เลือกกินมีปริมาณโปรตีนน้อยเกินไป **สำหรับผู้ที่ยังเลือกกินไข่ได้** อาจจะเพิ่มเมนูไข่เข้าไปได้ เช่น ไข่ต้ม ไข่ดาว (น้ำ/น้ำมัน) ไข่เจียวใส่ผัก หรือเต้าหู้ต่าง ๆ โปรตีนเกษตรก็ได้ โดยเลือกกินเมนูใดเมนูหนึ่งเท่านั้น และปริมาณเพียงแค่ 1 ฟองต่อมื้อ</li>
        <li>4. ในมื้อหลัก ควรเพิ่มผลไม้ ปริมาณ 1 กำปั้นด้วย และเลี่ยงการจิ้มพริกน้ำตาล กะปิ หรือน้ำปลาหวาน</li>
      </p>
      <p className="nutritionText3 bold color1"> ข้อควรรู้สำหรับการเลือกกินตามแนวทางที่แนะนำ</p>
      <p className="nutritionText4">
        <li>1. ควรเลี่ยงอาหารไขมันสูง เช่น ของทอด เครื่องดื่มและเบเกอรี่ต่าง ๆ ที่มีไขมันและน้ำตาลสูง เลี่ยงอาหารรสจัด เช่น เผ็ดจัด เค็มจัด เปรีี้ยวจัด และหวานจัด เป็นต้น</li>
        <li>2. ผลไม้หวานน้อย : ฝรั่ง ชมพู่ แก้วมังกร มะละกอ สับปะรด แคนตาลูป แอปเปิ้ล เบอร์รี่ต่าง ๆ เป็นต้น กินผลไม้อย่างน้อยวันละ 1-2 ฝ่ามือ สามารถสลับมื้อกินได้
          กรณีที่เป็นผลไม้ไขมันสูง เช่น ทุเรียน สามารถกินได้ ปริมาณ 1 พู ต่อมื้อ หรืออะโวคาโด้ กินได้ ½ ลูกเล็ก
          ต่อมื้อ และควรเลี่ยงหรือจำกัดปริมาณไขมันในมื้ออาหารอื่น ๆ ด้วย เพื่อไม่ให้ได้รับ
          ไขมันมากเกินไปในแต่ละวัน</li>
        <li>3.  หากปกติดื่มเครื่องดื่มร้อนในตอนเช้า เช่น กาแฟดำ สามารถดื่มได้ตามปกติ แต่หากดื่มกาแฟ/ชา
          ที่มีส่วนผสมของนม ควรเลือกชงดื่มเอง เช่น กาแฟดำผสมนมทางเลือกเล็กน้อย เลี่ยงการเติมน้ำตาล
          และครีมเทียมในปริมาณมาก หรือหากดื่มเครื่องดื่มแบบเย็นระหว่างวัน อาจเลือกดื่มฟิตโตะ ดริงก์แทนได้
        </li>
        <li> 4. สามารถเลือก/ปรับวันในการกินฟิตโตะได้ตามความสะดวก แต่ยังคงจำนวนวันไว้เช่นเดิม เช่น
          เลือกกินฟิตโตะโปรตีน 5 วันต่อสัปดาห์ อาจจะเลือกกินเป็นวันธรรมดา 5 วัน หรือวันธรรมดา 4 วัน
          และวันหยุด 1 วัน เป็นต้น แนะนำให้วางแผนล่วงหน้าเอาไว้ทั้งสัปดาห์ เพื่อให้สามารถทำตามแผนได้ดียิ่งขึ้น
          ลดโอกาสลืมและหลุดจากโปรแกรมได้
        </li>
        <li>
          5. ในวันอื่น ๆ ที่กินอาหารมื้อหลักตามปกติ (ไม่ได้กินฟิตโตะโปรตีนทดแทนในบางมื้อ) ให้กินอาหารหลักครบทั้ง
          3 มื้อ และยังคงใช้หลักการเลือกอาหารตามแนวทางการกินเพื่อสุขภาพและใช้หลักการ 2-1-1
          ในการกำหนดปริมาณอาหารเช่นเดิม
        </li>
      </p>
    </>
  );
};
export default vegetarian_food_AdditionalAdvice;