import appLocaleData from 'react-intl/locale-data/th';
/* import thMessages from '../locales/th-TH'; */

const ThLang = {
    messages: {
        /* ...thMessages */
          /* next */
     "next": "ถัดไป",

     /* 01.navbar home */
     "navbarHome.login": "เข้าสู่ระบบ",
     "navbarHome.register": "ลงทะเบียน",
     "navbarHome.logout": "ออกจากระบบ",
     "navbarHome.profile": "ข้อมูลส่วนตัว",
     "navbarHome.email": "อีเมล",
     "navbarHome.ex_email": "กรุณากรอก Email (Example@mail.com)",
     "navbarHome.password": "รหัสผ่าน",
     "navbarHome.validationInformation": "กรุณากรอกข้อมูลให้ครบถ้วน",
     "navbarHome.validationEmail": "กรุณา ตรวจสอบ Email เเละ Password ให้ถูกต้อง",
     "navbarHome.forgotpassword": "ลืมรหัสผ่าน",
     "navbarHome.donAccount": " ยังไม่เป็นสมาชิก",
     "navbarHome.createAccount": "ลงทะเบียน",
     "navbarHome.exerciseprogram": "โปรแกรมออกกำลังกาย",
     "navbarHome.foodsupplements": "วิธีการกินอาหารและอาหารเสริม",
     "navbarHome.admin": "Admin",
     /* 02.programPackage */
     "programPackage.registerBebeStayfit": "ลงทะเบียนเข้าร่วม Bebe Stayfit",
     "programPackage.calculate": "กรอกข้อมูลเพื่อคำนวณระยะเวลา ที่ใช้ในการลดน้ำหนัก",
     "programPackage.loseWeight": "วิธีการลดน้ำหนัก",
     "programPackage.convenientLoseWeight": "คุณสะดวก หรือต้องการลดน้ำหนักด้วยวิธีใด",
     "programPackage.dietAndExercise": "คุมอาหาร และ ออกกำลังกาย",
     "programPackage.onlydiet": "คุมอาหารอย่างเดียว",
     "programPackage.selectUnit": "เลือกหน่วย",
     "programPackage.pound": "ปอนด์",
     "programPackage.kilogram": "กิโลกรัม",
     "programPackage.manyKilograms": "คุณต้องการลดน้ำหนักกี่กิโลกรัม",
     "programPackage.ableLose": "คุณจะสามารถลดน้ำหนัก",
     "programPackage.kgIn": "ภายใน",
     "programPackage.months": "เดือน",
     "programPackage.choosePackage": "กรุณาเลือกแพ็กเกจที่คุณต้องการ",
     "programPackage.beginner": "เซตเริ่มต้นสายฟิต",
     "programPackage.price": "ราคา",
     "programPackage.baht": "บาท",
     "programPackage.2months": "*ระยะเวลา 2 เดือน และไม่มีการต่ออายุ",
     "programPackage.regular": "ราคาปกติสำหรับโปรแกรม 2 เดือน",
     "programPackage.exercise": "โปรแกรมออกกำลังกาย",
     "programPackage.dietary": "คำแนะนำการควบคุมอาหาร",
     "programPackage.foodSupplement": "คำแนะนำการทานอาหารเสริม",
     "programPackage.fittoPlant": "Fitto Plant Protein 6 กล่อง",
     "programPackage.chooseFlavor": "เลือกรสชาติได้",
     "programPackage.saveMore": "ประหยัดกว่า",
     "programPackage.applyProgram": "สมัครตามระยะเวลาของโปรแกรม",
     "programPackage.specialPrice": "*ราคาพิเศษสำหรับ 2 เดือนแรก เมื่อครบกำหนดจะชำระต่อเป็นรายเดือน (เดือนละ 1,800 บาท) จนกว่าจะครบตามระยะที่โปรแกรมแนะนำ **สามารถพักการชำระรายเดือนได้ทุกเวลาตามที่ต้องการ",
     "programPackage.specialPriceFirst": "ราคาพิเศษสำหรับโปรแกรม 2 เดือนแรก",
     "programPackage.auto-renewal": "ต่ออายุอัตโนมัติตามระยะเวลาของโปรแกรม",
     "programPackage.6boxes/2Month": "Fitto Plant Protein 6 กล่อง (เลือกรสชาติได้)",
     "programPackage.1month3boxes": "ทานเดือนละ 3 กล่อง",
     "programPackage.free": "ฟรีของแถม",
     "programPackage.select": "เลือกแพ็กเกจนี้",
     "programPackage.resultsObtained": "ผลลัพธ์ที่ได้เป็นค่าทางสถิติ อาจมีการเปลี่ยนแปลงขึ้นอยู่กับสภาวะของแต่ละบุคคล",
     "programPackage.firstonly": "เฉพาะสั่งซื้อครั้งแรก",
     "programPackage.shaker": "Shaker 1 ชิ้น",
     "programPackage.greenLemonade": "รส Green Lemonade จำนวน 1 ซอง",
     "programPackage.arabicaLatte": "รส Arabica Latte จำนวน 1 ซอง",
     "programPackage.collaC": "จำนวน 1 ซอง",
     
 
     
     /* 03.register */
     "register.chooseYouPackage": "เลือกแพ็กเกจ",
     "register.chooseYouAccount": "สร้างบัญชี",
     "register.chooseYouflavor": "เลือกรสชาติอาหารเสริม",
     "register.deliveryAddress": "ที่อยู่จัดส่ง",
     "register.payment": "ชำระเงิน",
     "register.confirmPassword": "ยืนยันรหัสผ่าน",
     "register.phoneNumber": "เบอร์โทรศัพท์",
     "register.displayname": "ชื่อที่ใช้แสดงในระบบ",
     "register.createAccount": "สร้างบัญชี Bebe Stayfit",
     "register.accountCredited": "บัญชีที่จะเข้าใช้งานในระบบของ ",
     "register.passwordsnotMatch": "รหัสผ่านไม่ตรงกัน กรุณากรอกใหม่ ",
     "register.passwordtooShort": "รหัสผ่านสั้นเกินไป",
     "register.numberCharacters": "รหัสผ่านจำนวนต้องมากกว่า 6 ตัวอักษร",
     "register.ex.phone": "ตัวอย่าง 08XXXXXXXX",
 
      /* 04. fitto_plant_protein */
      "fitto_plant_protein.chooseProtein": "เลือกรสชาติ Fitto Plant Protein",
      "fitto_plant_protein.youAreApplying": "หากคุณสมัครแบบ",
      "fitto_plant_protein.theDuration": "ตามระยะเวลาของโปรแกรม",
      "fitto_plant_protein.willBe": "จะสามารถเปลี่ยนแปลงรสชาติภายหลังได้",
      "fitto_plant_protein.box1": "กล่องที่ 1",
      "fitto_plant_protein.box2": "กล่องที่ 2",
      "fitto_plant_protein.box3": "กล่องที่ 3",
      "fitto_plant_protein.box4": "กล่องที่ 4",
      "fitto_plant_protein.box5": "กล่องที่ 5",
      "fitto_plant_protein.box6": "กล่องที่ 6",
 
      /* 05.shipping_address */
      "shipping_address.shippingAddress": "ที่อยู่ในการจัดส่งสินค้า",
      "shipping_address.name": "ชื่อ",
      "shipping_address.surname": "นามสกุล",
      "shipping_address.address": "ที่อยู่",
      "shipping_address.subdistrict": "แขวง/ตำบล",
      "shipping_address.district": "เขต/อำเภอ",
      "shipping_address.province": "จังหวัด",
      "shipping_address.postcode": "รหัสไปรษณีย์",
      "shipping_address.taxinvoice": "ขอใบเสร็จรับเงิน/ใบกำกับภาษี",
      "shipping_address.addressInvoice": "ที่อยู่ใบเสร็จรับเงิน/ใบกำกับภาษี",
      "shipping_address.individual": "บุคคลธรรมดา",
      "shipping_address.juristic": "นิติบุคคล",
      "shipping_address.taxpayer": "ชื่อผู้เสียภาษี",
      "shipping_address.nameJuristic": "ชื่อนิติบุคคล",
      "shipping_address.office": "สำนักงานใหญ่/สาขา",
      "shipping_address.identification": "เลขประจำตัวผู้เสียภาษี",
      "shipping_address.phoneNumber": "หมายเลขโทรศัพท์",
      "shipping_address.sameAddress": "ใช้ที่อยู่จัดส่งสินค้า",
      "shipping_address.cancel": "ยกเลิก",
      "shipping_address.confirm": "ยืนยัน",
 
      /* 06.payment */
      "payment.payment": "การชำระเงิน",
      "payment.credit": "บัตรเครดิต/เดบิต",
      "payment.errorBilling": "ระบบเรียกเก็บเงินไม่สำเร็จกรุณาตรวจสอบข้อมูลบัตรให้ถูกต้องอีกครั้ง หรือเปลี่ยนวิธีการชำระเงิน",
      "payment.qrcode": "ชำระด้วย QR Code",
      "payment.cardNumber": "16 digit card number",
      "payment.nameCard": "Name on card",
      "payment.expirationDate": "Expiration date",
      "payment.cvv": "CVV Code",
      "payment.yourPackage": "แพ็กเกจของคุณ",
      "payment.applyProgram": "สมัครตามระยะเวลาของโปรแกรม",
      "payment.baht2months": "3750 Baht / 2 months",
      "payment.collectPayment": "We will collect payment every 2 months.",
      "payment.pay": "ชำระเงิน",
      "payment.messagQrcode1": "1. หลังกด “ชำระเงิน” คุณจะได้รับ QR Code เพื่อชำระ",
      "payment.messagQrcode2": "2. เปิดแอปพลิเคชันธนาคารที่คุณมี",
      "payment.messagQrcode3": "3. ไปยังเมนู “สแกน” หรือ “สแกนจ่าย”",
      "payment.messagQrcode4": "4. ตรวจสอบชื่อบัญชีและยอดที่คุณต้องชำระก่อนทำรายการ",
      
       /* 07.welcome_new_member */
     "welcome_new_member.welcome": "ยินดีต้อนรับสมาชิกใหม่ของเรา",
     "welcome_new_member.thankYou": "ขอบคุณที่มาสมัครเข้าร่วมเป็นสมาชิก Bebe Stay Fit ทางทีมงานได้รับข้อมูลการสมัครเรียบร้อยแล้ว เราขอบคุณที่ท่านให้ความสนใจและเลือกเราเป็นส่วนหนึ่งสู่ความสำเร็จ  มาสร้างวินัย และมีความสุขกับการออกกำลังกายนะคะ",
     "welcome_new_member.getStart": "เริ่มต้นใช้งาน",
     "welcome_new_member.incorrectPassword": "รหัสผ่านไม่ถูกต้อง",
     "welcome_new_member.backtohome": "กลับหน้า Home",
     "welcome_new_member.clickLogin": "แล้วคลิก 'เข้าสู่ระบบ'",
 
     /* 08.reset_password */
     "reset_password.forgetPassword": "ลืมรหัสผ่าน",
     "reset_password.yourEmail": "โปรดกรอก อีเมลของ คุณจะได้รับลิงค์สำหรับสร้างรหัสผ่านใหม่ทางอีเมล",
     "reset_password.yourEmail": "โปรดกรอก อีเมลของคุ คุณจะได้รับลิงค์สำหรับสร้างรหัสผ่านใหม่ทางอีเมล",
     "reset_password.pleasecheck": "กรุณาตรวจสอบสอบ Email: ",
     "reset_password.afresh": "อีกครั้ง",
 
     /* 09.reset_password_succeed */
     "reset_password_succeed.newpassword": "เราได้ส่งลิงค์สำหรับสร้างรหัสผ่านใหม่ ไปทางอีเมลเรียบร้อยแล้วหากไม่พบอีเมลโปรดตรวจสอบที่เมลขยะ",
      "reset_password_succeed.orcontact": "หรือติดต่อมาหาเราได้ที่ 086-424-5141",
      "reset_password_succeed.from": "(ตั้งแต่เวลา 6:00-18:00น.)",
      "reset_password_succeed.backtomain": "กลับสู้หน้าหลัก",
 
     /* 10.videoList */
     "videoList.challenge": "ชาเลนจ์",
     "videoList.total": "รวมเวลาฝึกทั้งหมด",
      "videoList.mins": "นาที",
      "videoList.autoPlay": "เล่นต่อเนื่องอัตโนมัติ",
      "videoList.letsStart": "เริ่มกันเลย!",
      "videoList.succeed": "สำเร็จแล้ว!",
      "videoList.currentweight": "กรุณากรอกน้ำหนักปัจจุบันของคุณ",
      "videoList.off": "ปิด",
      "videoList.enterweekly": "กรุณากรอกข้อมูลรายสัปดาห์ เพื่อที่คุณจะได้รับประสบการณ์",
      "videoList.checkinformation": "กรุณาตรวจสอบข้อมูลอีกครั้งเพื่อที่คุณจะได้รับประสบการณ์",
      "videoList.programjust": "โปรแกรมออกกำลังกายสำหรับคุณโดยเฉพาะ",
      "videoList.weight": "น้ำหนัก (กก.)",
      "videoList.height": "ส่วนสูง (ซม.)",
      "videoList.pleasefill": "กรุณากรอกข้อมูล",
      "videoList.mustnot": "กรุณากรอกอายุ ห้ามเป็นเลขทศนิยม",
      "videoList.measureyour": "กรุณาวัดสัดส่วนของคุณ",
      "videoList.usingsample": "โดยใช้รูปตัวอย่างเพื่อเป็นไกด์ในการวัดสัดส่วน",
      "videoList.chest": "รอบอก (นิ้ว)",
      "videoList.waistline": "รอบเอว (นิ้ว)",
      "videoList.hip": "สะโพก (นิ้ว)",
      "videoList.goback": "ย้อนกลับ",
      "videoList.particulars": "สรุปรายละเอียด",
      "videoList.exerciseaccording": "วิธีออกกำลังกายตามโปรแกรม",
      "videoList.workout": "Workout Routine",
 
      /* 11.profile */
      "profile.editInformation": "แก้ไขข้อมูล",
      "profile.changePassword": "เปลี่ยนรหัสผ่าน",
      "profile.cut-offdate": "วันตัดรอบบิล",
      "profile.billinghistory": "ประวัติการเรียกเก็บ",
      "profile.save": "บันทึก",
      "profile.informationwill": "ข้อมูลการชำระเงินจะปรากฎในบัญชี",
      "profile.maytake": "อาจจะใช้เวลาสองถึงสามวันหลังจากวันที่ตัดรอบบิล",
      "profile.paymentdate": "วันที่ชำระ",
 
       /* 12.basic_information */
       /**  
      * ! ยังไม่เเปล */
      "basic_information.fillinbasic": "กรอกข้อมูลเบื้องต้นเพื่อเริ่มต้นการใช้งาน",
      "basic_information.displayname": "ชื่อที่ใช้แสดงในระบบ",
      "basic_information.fillingin": "การกรอกข้อมูลจะทำให้เราสามารถออกแบบ",
      "basic_information.anexercise": "โปรแกรมออกกำลังกายให้เหมาะสมกับคุณได้ดียิ่งขึ้น",
      "basic_information.gender": "เพศ",
      "basic_information.male": "ชาย",
      "basic_information.female": "หญิง",
      "basic_information.age": "อายุ",
      "basic_information.weight": "น้ำหนัก",
      "basic_information.height": "ส่วนสูง",
      "basic_information.youaresure": "คุณแน่ใจว่าสามารถฝึกท่าฝึกยากๆ เช่นท่า Squat, ท่ากระโดด ได้อย่างถูกต้อง",
      "basic_information.sure": "แน่ใจ",
      "basic_information.unsure": "ไม่แน่ใจ",
      "basic_information.createExercise": "สร้างโปรแกรมออกกำลังกาย",
      "basic_information.selectunit": "เลือกหน่วย",
      "basic_information.year": "ปี",
 
      /* 13.challenge */
      "challenge.allMission": "ภารกิจทั้งหมด",
      "challenge.teamlist": "รายชื่อภายในทีม",
      "challenge.teamscoreboard": "กระดานคะแนน",
      "challenge.friendlist": "รายชื่อเพื่อน",
      "challenge.rules": "กฎและกติกา",
      "challenge.nameyourteam": "ตั้งชื่อทีมของคุณ",
      "challenge.6alphabet": "ชื่อทีมต้องมากกว่า 6 ตัวอักษร",
      "challenge.teamsystem": "มีชื่อทีมนี้ในระบบแล้ว",
      "challenge.confirmationname": "ยืนยันการตั้งชื่อ",
      "challenge.notNameyourteam": "คุณยังไม่มีทีม",
      "challenge.allteamsfull": "ระบบไม่สามารถสุ่มเข้าทีมให้ได้ เนื่องจากทุกทีมมีสมาชิกครบแล้ว กรุณาสร้างทีมใหม่ด้วยตนเอง หรือรอจนกว่าจะเกิดการสร้างทีมใหม่และลองสุ่มอีกครั้ง",
      "challenge.createyourteam": "สร้างทีมของคุณ",
      "challenge.randomteam": "สุ่มเข้าร่วมทีม",
      "challenge.invitefriends": "ขอเป็นเพื่อน",
      "challenge.addedfriends": "เพิ่มเพื่อน",
      "challenge.sendrequest": "ส่งคำขอ",
      "challenge.notfriendssystem": "ไม่พบผู้ใช้ที่ต้้องการเพิ่มเพื่อนอยู่ในระบบ",
      "challenge.notfriends": "คุณยังไม่มีเพื่อน",
      "challenge.howincreasefriends": "วิธีการเพิ่มจำนวนเพื่อน",
      "challenge.points": "คะเเนน",
      "challenge.points2": "คะเเนน",
      "challenge.singleChallenge": "รายการชาเลนจ์แบบเดี่ยว",
      "challenge.teamChallenge": "รายการชาเลนจ์แบบทีม",
      "challenge.inviteteam": "ชวนเข้าทีม",
      "challenge.sendinvitation": "ส่งคำเชิญ",
      "challenge.eavingteam": "คุณยืนยันที่จะออกจากทีมหรือไม่",
      "challenge.Leaveteam": "ออกจากทีม",
      "challenge.pointsdetails": "รายละเอียดคะแนน",
      "challenge.confirmdeletion": "ยืนยันการลบเพื่อน",
      "challenge.remove": "คุณต้องการลบ",
      "challenge.fromfriend": "ออกจากรายชื่อเพื่อนหรือไม่",
      "challenge.deletion": "ลบเพื่อน",
      "challenge.friendsRequest": "คำขอเป็นเพื่อน",
      "challenge.yourfriend": "ต้องการเป็นเพื่อนกับคุณ",
      "challenge.refuse": "ปฎิเสธ",
      "challenge.accept": "ยอมรับ",
      "challenge.increase": "วิธีการเพิ่มจำนวนเพื่อน",
      "challenge.canstart": "• เริ่มต้นสามารถ add เพื่อนได้ 1 คน",
      "challenge.activeweek": "• ทำ 1 active week (ออกกำลังกายครบอย่างน้อย 1 วัน) จะสามารถเพิ่มเพื่อนได้อีก 2 คน",
      "challenge.maximum": "• จำนวนเพื่อนมีสูงสุดได้ 15 คน",
      "challenge.emilfriend": "อีเมลเพื่อนของคุณที่สมัคร Bebe Stayfit",
      "challenge.teampoint": "คะแนนทีม",
      "challenge.teamsingle": "คะแนนเดี่ยว",
      "challenge.pointfriend": "คะแนนเพื่อน",
      "challenge.completeweighing": "ทีมชั่งน้ำหนักครบ",
      "challenge.time": "ครั้ง",
      "challenge.weigh2": "ชั่งน้ำหนัก 2 ครั้ง ใน 1 สัปดาห์",
      "challenge.completeweighing7": "ทีมชั่งน้ำหนักครบ 7 วัน",
      "challenge.weightloss": "น้ำหนักลดลงจากสัปดาห์ก่อน",
      "challenge.4days": "ออกกำลังกายครบ 4 วัน",
      "challenge.resetSunday": "*รายการจะถุูก Reset ทุกวันอาทิตย์",
      "challenge.scoresSunday": "*คะแนนจะถูกสรุปทุกวันอาทิตย์",
      "challenge.join": "เข้าร่วมทีม",
      "challenge.joinChallenge": "คำชวนเข้าร่วมทีมชาเลนจ์",
      "challenge.wouldlike": "ต้องการชวนคุณเข้าร่วมทีม",
      "challenge.10point": "จะได้รับ 10",
      "challenge.weighed2times": "ชั่งน้ำหนักครบ 2 ครั้ง",
      "challenge.exercise4days": "ออกกำลังกายครบ 4 วันต่อสัปดาห์",
      "challenge.allmembers": "สมาชิกทุกคนชั่งน้ำครบ 2 ครั้ง",
      "challenge.teamreceive": "ทั้งทีมจะได้รับ คนละ",
      "challenge.eachDay": "ในเเต่ละวันมีสมาชิกอย่างน้อย 1 คน ชั่งน้ำหนัก",
      "challenge.7day": "- ครบ 7 วัน",
      "challenge.6day": "- ครบ 6 วัน",
      "challenge.5day": "- ครบ 5 วัน",
      "challenge.4day": "- ครบ 4 วัน",
      "challenge.3day": "- ครบ 3 วัน",
      "challenge.2day": "- ครบ 2 วัน",
      "challenge.1day": "- ครบ 1 วัน",
      "challenge.thatweek": "หากสัปดาห์นั้นอยู่ใหน",
      "challenge.extrapoints": "จะได้รับคะแนนพิเศษ",
      "challenge.updatepoints": "ระบบจะอัปเดตคะเเนนทุกวันอาทิตย์เวลา 00.00 น.",
      "challenge.teammembers": "สมาชิกในทีม",
      "challenge.10members": "1 ทีม จะมีสมาชิกจำนวน 10 ท่าน โดยระบบจะทำการจัดทีมให้อัตโนมัติหากสมาชิกหมดอายุก่อนจบ Season ระบบจะตัดออกจากกลุ่มใน 7 วัน",
      "challenge.upRank": "การเลื่อนขั้น (Rank)",
      "challenge.dividedinto": "ระดับขั้นจะแบ่งออกเป็น Newbie, Bronze, Silver, Gold และ Platinum ในแต่ละสัปดาห์ถ้ามีคะแนนรวมมากกว่า 40 คะแนนจะได้รับการเลื่อนขั้นแต่หากคะแนนน้อยกว่าหรือเท่ากับ 40 คะแนนจะถูดลดขั้นลงมา 1 ลำดับ โดยระบบจะทำการอัปเดตคะแนนทุกวันอาทิตย์เวลา 00.00 น.",
      "challenge.collectingpoints": "การสะสมคะแนน",
      "challenge.individualpoints": "คะแนนส่วนบุคคล",
      "challenge.areearned": "จะได้รับจากภารกิจ โดยจำนวนคะแนนที่ได้รับนั้นจะขึ้นอยู่กับ Rank ในแต่ละสัปดาห์ ยิ่ง Rank สูงจะได้คะแนนมากขึ้น คะแนนของทีม จะเป็นคะแนนสะสมรวมของสมาชิกถ้าคนในทีมทำภารกิจสำเร็จ ผู้ร่วมทีมจะได้รับคะแนนด้วยเช่นกัน",
      "challenge.season": "รายละเอียดของรางวัลประจำ Season",
      "challenge.trackedFacebook": "สามารถติดตามของรางวัลได้ทาง Facebook Group",
      "challenge.friend": "เพื่อน",
      "challenge.person": "คน",
      "challenge.team": "ทีม",
      "challenge.number": "จำนวนสมาชิก",
      "challenge.friendRequest": "ไม่มีภารกิจ เนื่องจากไม่ได้อยู่ในระยะเวลาของชาเลนจ์",
      "challenge.addFriend": "เพิ่มเพื่อน",
      "challenge.pending": "รอการยืนยัน",
      "challenge.cancelRequest": "ยกเลิกคำขอ",
      "challenge.find": "ค้นหา",
      "challenge.addedtoYourFriends": "เพื่อนของคุณ",
      "challenge.name_email": "ชื่อ หรืออีเมลเพื่อนของคุณที่สมัคร Bebe Stay Fit",
      "challenge.activeUsers": "ผู้ใช้งานทั้งหมดในระบบ",
      "challenge.noResultsFound": "ไม่มีชื่ออยู่ในระบบ",

       /* 14.cancel_package_new */
       "cancel_package_new.unsubscribe": "ยกเลิกบริการ",
       "cancel_package_new.bebeStayFit": "Bebe Stay Fit",
       "cancel_package_new.yourPackage": "แพ็กเกจของคุณ",
       "cancel_package_new.basedOnProgramDuration": "ตามระยะเวลาของโปรแกรม",
       "cancel_package_new.onceTheSubscription": "*หากยกเลิกตอนนี้ระบบจะทำการหยุดเรียกเก็บเงินอัตโนมัติ",
       "cancel_package_new.pleaseTellUsTheReaso": "โปรดบอกเหตุผลในการยกเลิก  เพื่อที่เราจะได้พัฒนาสินค้าและบริการให้ดียิ่งขึ้น",
       "cancel_package_new.pleaseSpecify_1": "โปรดบอกชื่อโปรแกรม...",
       "cancel_package_new.pleaseSpecify_2": "โปรดบอกชื่อผลิตภัณฑ์...",
       "cancel_package_new.finishCancellation": "ยืนยัน",
       "cancel_package_new.month": "เดือน",
       "cancel_package_new.yourSubscription": "ยกเลิกบริการ Bebe Stay Fit สำเร็จ",
       "cancel_package_new.thankYouForTrusting": "ขอบคุณที่ใช้บริการของเรา Bebe Stay Fit ขอเป็นกำลังใจในการดูแลสุขภาพและฟิตหุ่นตามเป้าหมายของคุณนะคะ",
       "cancel_package_new.backProfile": "กลับหน้าโปรไฟล์",
       "cancel_package_new.midnight": "ทุกวัน 8:00 - 00:00",
       "cancel_package_new.phone": "โทร :",
       "cancel_package_new.monFri": "จันทร์ - ศุกร์ 9:00 - 18:00",
       "cancel_package_new.with": "(มีเเอด)",
       "cancel_package_new.contactOurStaff": "ติดต่อเจ้าหน้าที่",
       "cancel_package_new.get5": "เพื่อรับส่วนลด 5%",
       "cancel_package_new.continuingYour": "สำหรับการต่ออายุคอร์ส Bebe Stay Fit",
       "cancel_package_new.close": "ปิด",
       "cancel_package_new.contactOurStaffTo": "ติดต่อเจ้าหน้าที่เพื่อขอรับบริการ",
       "cancel_package_new.yourExercise": "เฉพาะโปรแกรมออกกำลังกายได้นะคะ",
       "cancel_package_new.likeOurProduct": "ถ้าคุณยังสนุกกับการออกกำลังกายแต่ไม่อยากใช้ ผลิตภัณฑ์ของเราสามารถติดต่อเจ้าหน้าที่เพื่อขอรับ บริการเฉพาะโปรแกรมออกกำลังกายได้นะคะ",
       "cancel_package_new.contactUs": "ติดต่อเจ้าหน้าที่",
       "cancel_package_new.cancelSubscription": "ยกเลิกบริการ",
       "cancel_package_new.onsultOurSpecialists": "ปรึกษาผู้เชี่ยวชาญ",
       "cancel_package_new.customMake": "เพื่อออกแบบโปรแกรมพิเศษเฉพาะคุณ",
       "cancel_package_new.youAreNotSeeing": "ถ้าคุณยังไม่พอใจกับผลลัพธ์ที่ได้แต่อย่าเพิ่งถอดใจ นะคะ เพราะการลดน้ำหนักอย่างยั่งยืนนั้นต้องใช้เวลา ซึ่งคุณจะทำได้อย่างแน่นอน เบเบ้เป็นกำลังใจให้ค่ะ",
       "cancel_package_new.youAreNotSeeingWorking": "ถ้าคุณยังสนุกกับการออกกำลังกายแต่ไม่อยากใช้ ผลิตภัณฑ์ของเราติดต่อเจ้าหน้าที่เพื่อขอรับบริการ เฉพาะโปรแกรมออกกำลังกายได้นะคะ",
       "cancel_package_new.contactStaffToCustomise": "ติดต่อเจ้าหน้าที่เพื่อขอรับบริการ",
       "cancel_package_new.exerciseProgram": "เฉพาะโปรแกรมออกกำลังกายได้นะคะ",
       "cancel_package_new.ifYouLikeOurProduct": "ถ้าคุณชื่นชอบผลิตภัณฑ์ของเราแต่ไม่สะดวกที่จะออกกำลังกายกรุณาติดต่อเจ้าหน้าที่เพื่อรับคำแนะนำพิเศษได้นะคะ",
       "cancel_package_new.alwaysKeep": "ออกกำลังกายและคุมอาหารอย่างสม่ำเสมอ เพื่อหุ่นและสุขภาพที่ดีอย่างยั่งยืนกันนะคะ",
       "cancel_package_new.ultimate4day": "หุ่นในฝัน เป็นจริงได้ แค่ออกกำลังกาย สัปดาห์ละ 4 วัน เบเบ้เป็นกำลังใจให้นะคะ",
       "cancel_package_new.ourWorkoutProgram": "โปรแกรมของเราอาจจะเหนื่อย และยากในช่วงแรก แต่อย่าเพิ่งท้อนะคะ เพราะคุณจะแข็งแรง และทำได้ดีขึ้นอย่างแน่นอน หุ่นในฝันไม่ไกลเกินเอื้อม  เบเบ้เป็นกำลังใจให้ค่ะ :",
       "cancel_package_new.imIn": "รับข้อเสนอ",
    },
    locale: 'th-TH',
    data: appLocaleData
};
export default ThLang;