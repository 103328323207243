import React from 'react';
import basedProgram from "../../assets/img/foot_supplement/based-butrition-program.jpg";


const AdditionalAdvice = function () {
  return (
    <>
      <p className="nutritionHead2">คำแนะนำเพิ่มเติม</p>
      <p className="nutritionText3">
        ไม่แนะนำให้กิน Fitto Plant Protein เป็นมื้อเช้าและมื้อเที่ยงติดต่อกัน และกินอาหารปกติในมื้อเย็น ควรมีมื้อปกติคั่นกลาง หรือเริ่มด้วยมื้อปกติก่อน จะช่วยลดความหิวและลดโอกาสเสี่ยงที่จะกินอาหารมื้อปกติเกินปริมาณได้
      </p>
      <p className="nutritionText3 bold"> คำอธิบายการเลือกกินตามแนวทางที่แนะนำ</p>
      <p className="nutritionText3">1. มื้อที่เลือกกิน Fitto Plant Protein</p>
      <p className="nutritionText4">
        <li>ลดเครื่องดื่มที่มีรสหวาน เช่น น้ำอัดลม ชา กาแฟที่ชงรสหวาน</li>
      </p>
      <p className="nutritionText3">2. มื้ออาหารหลัก</p>
      <p className="nutritionText4">
        1. ควรเลือกเมนูที่ได้สัดส่วนตามหลัก 2-1-1 ยกตัวอย่างเช่น
      </p>
      <p className="nutritionText2">
        <li>หมูปิ้งไม่ติดมัน 5 ไม้ ข้าวเหนียว 1 ห่อเล็ก และผักสด</li>
        <li>ขนมปัง 2 แผ่น ไข่คน 2 ฟอง และผักสด</li>
        <li>เมนูข้าวราดแกงที่มีเนื้อสัตว์และผัก เช่น ไก่ผัดขิง (เนื้อไก่ประมาณ 4-5 ช้อน) ไข่ต้ม น้ำพริกกะปิ ผักลวก/สด</li>
        <li>ก๋วยเตี๋ยวน่องไก่ตุ๋น เพิ่มผัก</li>
        <li>ส้มตำ ไก่ย่าง ข้าวเหนียว</li>
        <li>สเต็กหมู ไก่ ปลา กินกับผักย่างหรือผักสด</li>
        <li>ข้าวขาหมู พิเศษเนื้อ ไม่หนัง ไม่ราดน้ำ เพิ่มผักลวก</li>
      </p>
      <p className="nutritionText2">
        2. มื้ออาหารหลัก ควรเลือกกินเมนูใดเมนูหนึ่งเท่านั้น
      </p>
      <p className="nutritionText2">
        3. หากเมนูที่เลือกกินมีปริมาณโปรตีนน้อยเกินไป อาจจะเพิ่มเมนูไข่เข้าไปได้ เช่น ไข่ต้ม ไข่ดาว (น้ำ/น้ำมัน)     ไข่เจียว โดยเลือกกินเมนูใดเมนูหนึ่งเท่านั้น และปริมาณเพียงแค่ 1 ฟองต่อมื้อ
      </p>
      <p className="nutritionText2">
        4. เมนูข้างต้น เป็นเพียงเมนูแนะนำเท่านั้น ไม่จำเป็นต้องกินตามอย่างเคร่งครัดและจำกัด สามารถเลือกกินเมนูอื่น ๆ ที่ใกล้เคียง หรือเมนูที่พอหาได้ตามความสะดวก
      </p>
      <p className="nutritionText2">
        5. ในมื้อหลัก ควรเพิ่มผลไม้ ปริมาณ 1 ฝ่ามือเข้าไปด้วย
      </p>
      <p className="nutritionText3 bold color1"> ข้อควรรู้สำหรับการเลือกกินตามแนวทางที่แนะนำ</p>
      <p className="nutritionText2">
        1. เลือกเมนูอาหารตามแนวทางการกินเพื่อสุขภาพและใช้หลักการ 2-1-1 ในการกำหนดปริมาณอาหาร
      </p>
      <p className="nutritionText2">
        2. ควรเลี่ยงอาหารไขมันสูง เช่น ของทอด เครื่องดื่มและเบเกอรี่ต่าง ๆ ที่มีไขมันและน้ำตาลสูง เลี่ยงอาหารรสจัด เช่น เผ็ดจัด เค็มจัด เปรีี้ยวจัด และหวานจัด เป็นต้น
      </p>
      <p className="nutritionText2">
        3. ผลไม้หวานน้อย : ฝรั่ง ชมพู่ แก้วมังกร มะละกอ สับปะรด แคนตาลูป แอปเปิ้ล เบอร์รี่ต่าง ๆ เป็นต้น กินผลไม้อย่างน้อยวันละ 1-2 ฝ่ามือ สามารถสลับมื้อกินได้กรณีที่เป็นผลไม้ไขมันสูง เช่น ทุเรียน สามารถกินได้ ปริมาณ 1 พู ต่อมื้อ หรืออะโวคาโด้ กินได้ ½ ลูกเล็ก ต่อมื้อ และควรเลี่ยงหรือจำกัดปริมาณไขมันในมื้ออาหารอื่น ๆ ด้วย เพื่อไม่ให้ได้รับไขมันมากเกินไปในแต่ละวัน
      </p>
      <p className="nutritionText2">
        4. หากปกติดื่มเครื่องดื่มร้อนในตอนเช้า เช่น กาแฟดำ สามารถดื่มได้ตามปกติ แต่หากดื่มกาแฟ/ชา ที่มีส่วนผสมของนม ควรเลือกชงดื่มเอง เช่น กาแฟดำผสมนมสดเล็กน้อย เลี่ยงการเติมน้ำตาล และครีมเทียมในปริมาณมาก หรือหากดื่มเครื่องดื่มแบบเย็นระหว่างวัน อาจเลือกดื่มฟิตโตะ ดริงก์แทนได้
      </p>
      <p className="nutritionText2">
        5. สามารถเลือก/ปรับวันในการกินฟิตโตะได้ตามความสะดวก แต่ยังคงจำนวนวันไว้เช่นเดิม เช่น เลือกกินฟิตโตะโปรตีน 5 วันต่อสัปดาห์ อาจจะเลือกกินเป็นวันธรรมดา 5 วัน หรือวันธรรมดา 4 วัน และวันหยุด 1 วัน เป็นต้น แนะนำให้วางแผนล่วงหน้าเอาไว้ทั้งสัปดาห์ เพื่อให้สามารถทำตามแผนได้ดียิ่งขึ้น ลดโอกาสลืมและหลุดจากโปรแกรมได้
      </p>
      <p className="nutritionText2">
        6. ในวันอื่น ๆ ที่กินอาหารมื้อหลักตามปกติ (ไม่ได้กินฟิตโตะโปรตีนทดแทนในบางมื้อ) ให้กินอาหารหลักครบทั้ง 3 มื้อ และยังคงใช้หลักการเลือกอาหารตามแนวทางการกินเพื่อสุขภาพและใช้หลักการ 2-1-1 ในการกำหนดปริมาณอาหารเช่นเดิม
      </p>
    </>
  );
};
export default AdditionalAdvice;